<template>
  <div class="configure-right" :style="{height: '100%'}">
    <h2 style="padding:20px;">公司信息</h2>
    <a-row>
      <a-col :span="24" :offset="1">
        <a-form :form="form" :label-col="{ span: 2 }" :wrapper-col="{ span: 12 }" style="margin-left:6px">
          <a-form-item label="公司名称">
            <a-input placeholder="请输入公司名称"
                     v-decorator="['entName', { rules: [{message: '请输入公司名称' },{ validator: validateLoginName, trigger: 'blur' }] }]" />
          </a-form-item>
          <a-form-item label="公司logo" help="*建议上传长度为 200px，高度为 52px，背景透明的 PNG 格式图片。">
            <div class="ant-upload-preview">
              <a-upload v-decorator="['entLogoUrl']" list-type="picture-card" class="avatar-uploader"
                        :show-upload-list="false" :before-upload="beforeUpload" :customRequest="customRequest">
                <img v-if="entLogoUrl" :src="entLogoUrl" alt="avatar" style="width:100px;height:100px"/>
                <div v-else>
                  <a-icon :type="loadingFile ? 'loading' : 'plus'" />
                </div>
              </a-upload>
            </div>
          </a-form-item>
          <a-form-item :wrapper-col="{ offset:2 }">
            <a-button @click="dataFormSubmit()" type="primary">确定</a-button>
          </a-form-item>
        </a-form>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { getCompanyInfo,updateCompanyInfo } from '@/httpsAPI/configure/index'
import { uploadImg } from '@/httpsAPI/common'

export default {
  name: "companyInfo",
  data(){
    return{
      form: this.$form.createForm(this),
      entName:'',
      entLogoUrl:'',
      confirmLoading:false,
      loadingFile:false
    }
  },
  created(){
    this.getCompanyInfo()
  },
  methods:{
    // 公司名称长度验证 
    async validateLoginName (rule, value, callback) {
      if (value.length>6) {       
        callback(new Error('公司名称不允许超过6字符'))
      } else {
        callback()
      }
    },
     //logo上传
    beforeUpload (file) {
      console.log('beforeUpload')
      console.log(file)
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        this.$message.error('仅支持图片上传');
      }
      const isLt2M = file.size / 1024 / 1024 < 4;
      if (!isLt2M) {
        this.$message.error('图片不能大于4MB!');
      }
      return isJpgOrPng && isLt2M;
    },
    customRequest(option){
      const formData = new FormData() 
      formData.append('file', option.file)
      formData.append('channel', 2)
      this.saveFile(formData)
    },
    saveFile (formData) {
      console.log('saveFile')
      console.log(formData,"formData")
      this.loadingFile = true
      uploadImg(formData).then(res=>{
        console.log(res,"res")
          if (res.code == 10000) { 
            this.entLogoUrl = this.$imgBaseUrl + res.data.name
            this.loadingFile = false           
            this.form.setFieldsValue({
              entLogoUrl: res.data.name
            })
          } else {  
            this.$message.error(res.msg)  
          }
      })
    },
    //公司信息详情
    getCompanyInfo(){
      const that = this
      getCompanyInfo().then(res=>{
        this.form.setFieldsValue({
          entName:res.data.entName
        })
        this.entLogoUrl= this.$imgBaseUrl + res.data.entLogoUrl
      })
    },
    //修改公司信息
    dataFormSubmit (e) {
      this.confirmLoading = true;
      this.form.validateFields((err, values) => {
        if (err) {
          return;
        }
        updateCompanyInfo(values).then(res => {
          if (res.code == 10000) {
            this.$message.success({
              content: '操作成功',
              duration: 1,
              onClose: () => {
                this.confirmLoading = false;
                this.$emit('refreshDataList')
              }
            })
          } else {
            this.$message.error({
              content: res.msg,
              duration: 1
            })
          }
        })
      })
    },

  }
};
</script>

<style lang="less" scoped>
.configure-right {
  position: relative;
  .submit-container {
    position: absolute;
    top:350px;
    // bottom: 80px;
    left: 135px;
  }
}
</style>
