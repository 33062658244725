<template>
  <a-modal
    title="修改项目负责人"
    :visible="visible"
    :confirm-loading="confirmLoading"
    @ok="handleOk"
    @cancel="visible = false"
    destroyOnClose
  >
    <a-row>
      <a-col :md="14">
        <a-form-model ref="addForm" :model="form" :rules="rules" :label-col="{span:6}" :wrapper-col="{span:18}">
          <a-form-model-item label="负责人" prop="masterId">
            <a-select
                :filter-option="false" 
                show-search
                :allowClear="true"
                placeholder="请选择或搜索负责人"
                v-model="form.masterId"
                @search="handleSearch"
                >
                    <a-select-option v-for="item in masterList" v-bind:key="item.id" :value="item.id">
                        {{item.name }}
                    </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-form-model>
      </a-col>

    </a-row>
  </a-modal>
</template>

<script>
import {getCompanyUserListAll} from "@/httpsAPI/common";
import {getProjectTemplateSelect,getProjectSelect} from "@/httpsAPI/project";
import { updateProject } from '@/httpsAPI/configure/index'


export default {
  name: "addProject",
  props:{
    confirmLoading:{
      type:Boolean,
      default:false
    }
  },
  data(){
    return {
      visible: false,
      projectId:'',
      templateSelect:[],
      chooseIndex:0,
      tplImg :'',
      tplContent: '',
      projectSelect:[],
      checkedList:[],
      templateId:"",
      currentTab:'1',
      scrollPage: 1,
      masterData: [],
      keyword: '',
      masterList: [],
      timeRange:[],
      startTime: '',
      endTime: '',
      selectCheck:null,
      form:{
        projectName:"",
      },
      rules:{
        masterId:[
          {required:true,message:'请输入负责人',trigger: 'change'}
        ]
      }
    }
  },
  methods:{
    init(record){
      this.visible = true
      this.projectId = record.id

    },
    dateChange(date, dateString) {
      this.startTime = dateString[0]
      this.endTime = dateString[1]
    },
    // 下拉框 分页 搜索 负责人
    changeMasterList() {
      getCompanyUserListAll({name: this.keyword})
        .then(res => {
            this.masterList = res.data;
            this.masterData = res.data;
        })
    },
    //负责人下拉框滚动事件
    handlePopupScroll(e) {
        const { target } = e
        const scrollHeight = target.scrollHeight - target.scrollTop
        const clientHeight = target.clientHeight
        // 下拉框不下拉的时候
        if (scrollHeight === 0 && clientHeight === 0) {
            this.scrollPage = 1
        } else {
            // 当下拉框滚动条到达底部的时候
            if (scrollHeight < clientHeight + 5) {
                this.pageData(1);
            }else if (scrollHeight == 3208) {
                this.pageData(2);
            }
        }
    },
    handleSearch(keyword){
      this.keyword = keyword;
      this.changeMasterList();
    },

    //项目下拉框滚动事件
    projectHandlePopupScroll(e) {
        const { target } = e
        const scrollHeight = target.scrollHeight - target.scrollTop
        const clientHeight = target.clientHeight
        // 下拉框不下拉的时候
        if (scrollHeight === 0 && clientHeight === 0) {
            this.scrollPage = 1
        } else {
            // 当下拉框滚动条到达底部的时候
            if (scrollHeight < clientHeight + 5) {
                this.projectPageData(1);
            }else if (scrollHeight == 3208) {
                this.projectPageData(2);
            }
        }
    },
    projectHandleSearch(keyword){
      this.keyword = keyword;
      this.getProjectSelectHandle();
    },
    handleOk(){
      const that = this
      that.$refs.addForm.validate(valid =>{
        if (valid) {
          let parames = {
            projectId:that.projectId,
            masterId: that.form.masterId
          }
          updateProject(parames).then(res=>{
            if (res.code == 10000) {
              that.$message.success({
                content: '操作成功',
                duration: 1,
                onClose: () => {
                    this.visible = false;
                    this.$emit('refreshDataList')
                }
              })
            } else {
              that.$message.error({
                content: res.msg,
                duration: 1
              })
            }
          })
        }
      })
    },
    changeTemplate(key){
      this.currentTab=key
    },
    checkedTemplate(index){ 
      this.chooseIndex = index
      this.tplImg = window._CONFIG["imgPrefix"] + this.templateSelect[this.chooseIndex].img
      this.tplContent = this.templateSelect[this.chooseIndex].content
      this.templateId = this.templateSelect[this.chooseIndex].id
    },
    getTemplateSelect(){
      getProjectTemplateSelect({}).then(res=>{
          console.log(res)
          this.templateSelect = res.data.data
          this.templateId = this.templateSelect[this.chooseIndex].id
          this.tplImg = window._CONFIG["imgPrefix"] + this.templateSelect[this.chooseIndex].img
          this.tplContent = this.templateSelect[this.chooseIndex].content
      })
    },
    getProjectSelectHandle(){
      getProjectSelect({projectName: this.keyword, pageNo:1, pageSize: 1000}).then(res=>{
        this.projectSelect = res.data.data
        this.selectCheck = this.projectSelect[0].id
      })
    }
  },
  watch:{
    visible(val){
      if(val){
        this.changeMasterList()
        this.getTemplateSelect()
        this.getProjectSelectHandle()
      }
    }
  }
}
</script>

<style lang="less">
.ant-list-items{
  cursor: pointer;
}
.choose{
  padding-left: 10px;
  border-left: 3px solid #4a90e2;
  .ant-list-item-meta-title,.ant-list-item-meta-description{
    color: #4a90e2;
  }
  .ant-list-item-meta-description{
    font-size: 12px;
  }
}
</style>