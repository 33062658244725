<template>
  <a-modal
      title="项目详情"
      :width="720"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @cancel="handleCancel"
      :footer="null"
    >
      <a-form :form="form" :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }" >

            <a-form-item label="项目开启状态">
              <a-input v-decorator="['activeStatus', {initialValue:dataForm.activeStatus}]" :read-only="true"/>
            </a-form-item>
     
            <a-form-item label="项目归档状态">
              <a-input v-decorator="['archiveStatus', {initialValue:dataForm.archiveStatus}]" :read-only="true"/>
            </a-form-item>

            <a-form-item label="创建人">
              <a-input v-decorator="['createBy', {initialValue:dataForm.createBy}]" :read-only="true"/>
            </a-form-item>

            <a-form-item label="创建时间">
              <a-input v-decorator="['createTime', {initialValue:dataForm.createTime}]" :read-only="true"/>
            </a-form-item>

            <a-form-item label="开始时间">
              <a-input v-decorator="['startTime', {initialValue:dataForm.startTime}]" :read-only="true"/>
            </a-form-item>
 
            <a-form-item label="结束时间">
              <a-input v-decorator="['endTime', {initialValue:dataForm.endTime}]" :read-only="true"/>
            </a-form-item>

            <a-form-item label="公司名称">
              <a-input v-decorator="['entName', {initialValue:dataForm.entName}]" :read-only="true"/>
            </a-form-item>

            <a-form-item label="项目锁定状态">
              <a-input v-decorator="['lockStatus', {initialValue:dataForm.lockStatus}]" :read-only="true"/>
            </a-form-item>
   
            <a-form-item label="项目状态">
              <a-input v-decorator="['status', {initialValue:dataForm.status}]" :read-only="true"/>
            </a-form-item>
        
      </a-form>
    </a-modal>
</template>
<script>
import { detailProject } from '@/httpsAPI/configure/index'
    export default {
        data(){
            return {
                form: this.$form.createForm(this),
                visible: false,
                confirmLoading: false,
                treeData: [],
                dataForm: {
                  activeStatus:'',
                  archiveStatus:'',
                  lockStatus:'',
                  status:''
                },
                id:''
            }
        },
        methods:{
          handleCancel(e) {
            this.visible = false;
          },
          init (record) {
            this.id = record.id
            this.visible = true
            this.$nextTick(() => { 
              this.form.resetFields();
              detailProject(this.id).then(res=>{
                console.log(res.data,"data")
                this.dataForm = res.data
                this.dataForm.activeStatus = res.data.activeStatus == 0 ? '开启':'关闭'
                this.dataForm.archiveStatus = res.data.archiveStatus == 0 ? '未归档':'已归档'
                this.dataForm.lockStatus = res.data.lockStatus == 0 ? '未锁定':'已锁定'
                // this.dataForm.status = res.data.status == 0 ? '未锁定':'已锁定'
                if(res.data.status == 0){
                  this.dataForm.status = '未开始'
                }else if(res.data.status == 1){
                  this.dataForm.status = '进行中'
                }else{
                  this.dataForm.status = '已完成'
                }
              })
            });
          }
        }
    }
</script>