<template>
  <div class="configure-right" :style="{height: '100%'}">
    <h2 style="padding:20px;">工作日设置</h2>
    <a-alert message="工作日设置用于设置成员每周工作天数和每天工作标准时长，成员工作量统计和计算基于已设置的标准值" type="info" show-icon style="margin:0px 20px;" />
    <a-card :bordered="false">
      <a-row :gutter="50">
        <a-col :md="24" :style="{ minHeight: '180px' }">
          <a-form :form="form" layout="vertical">
            <a-form-item label="工作日">
              <a-checkbox-group v-decorator="['weekdays', {rules: [{required: true, message: '请选择工作日！'}] }]" >
                <a-checkbox
                    v-for="(item,index) in weekList"
                    :key="index"
                    :value="(index+1) + ''"  
                    >
                    {{weekList[index]}}
                  </a-checkbox>
              </a-checkbox-group>
            </a-form-item>

            <a-form-item :wrapper-col="{lg: {span: 20}, sm: {span: 15} }" label="标准工作时长">
              <a-input-group compact>
                  <a-input-number style="width: 25%" v-decorator="['times']" />
                  <a-input style="width: 12%" default-value="小时(h)/天" disabled />
                  <span>（请输入0-24之间的数字）</span>
                </a-input-group>
            </a-form-item>

            <a-form-item>
              <a-button type="primary" @click="handleSubmit">保存</a-button>
            </a-form-item>
          </a-form>
        </a-col>
      </a-row>
    </a-card>
  </div>
</template>

<script>
import { getWeekday, updateWeekday } from '@/httpsAPI/configure/index'

export default {
  name: "workSet",
  data () {
    return {
      weekdays:[],
      form: this.$form.createForm(this),
      labelCol: { span: 7 },
      wrapperCol: { span: 12 },
      number: {
        times: '',
      },
      weekList: ["周一", "周二", "周三", "周四", "周五", "周六", "周日"]
    };
  },
  created(){
    this.getWeekday()
  },
  methods: {
    //查询
    getWeekday(){
      getWeekday().then(res=>{
        this.form.resetFields();
        this.form.setFieldsValue({
            weekdays: res.data.weekdays,
            times: res.data.times
        })
      })
    },
    // 保存
    handleSubmit (e) {
      this.form.validateFields((err, values) => {
        if (err) {
          return;
        }
        console.log(values)
        updateWeekday(values).then(res => {
          if (res.code == 10000) {
            this.$message.success({
              content: '操作成功',
              duration: 1,
              onClose: () => {
              }
            })
          } else {
            this.$message.error({
              content: res.msg,
              duration: 1
            })
          }

        })
      })
    }
  }

}
</script>

<style scoped>
.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  font-weight: 900;
  font-size: 20px;
}
</style>