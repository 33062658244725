<template>
  <a-modal title="修改部门" :visible="visible" :confirm-loading="confirmLoading" @cancel="handleCancel">
    <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }" @keyup.enter.native="dataFormSubmit()">
      <a-form-item label="部门名称">
        <a-input
          v-decorator="['deptName', {initialValue:dataForm.deptName, rules: [{required: true, message: '请输入部门名称'}]}]"
          placeholder="请输入部门名称" />
      </a-form-item>
      <a-form-item label="部门简称">
        <a-input
          v-decorator="['deptShorterName', {initialValue:dataForm.deptShorterName, rules: [{ message: '请输入部门简称'}]}]"
          placeholder="请输入部门简称" />
      </a-form-item>
      <a-form-item label="部门排序">
        <a-input-number
          v-decorator="['sort', {initialValue:dataForm.sort}]"
          placeholder="请输入部门排序" />
      </a-form-item>
      <a-form-item label="描述">
        <a-textarea placeholder="请输入描述" :rows="4"
          v-decorator="['remark',  {initialValue:dataForm.remark, rules: [ { max:150, message: '描述' }] }]" />
      </a-form-item>
    </a-form>

    <span slot="footer" class="dialog-footer">
      <a-button @click="visible = false">取消</a-button>
      <a-button class="ant-btn ant-btn-primary" @click="dataFormSubmit()">确定</a-button>
    </span>
  </a-modal>
</template>
<script>
import { updateDeptTree, detailDeptTree } from '@/httpsAPI/configure/index';

export default {
  data () {
    return {
      form: this.$form.createForm(this),
      visible: false,
      confirmLoading: false,
      dataForm: {
        id: '',
        deptName: '',
        deptShorterName: '',
        remark: ''
      }
    }
  },
  methods: {
    dataFormSubmit (e) {
      this.confirmLoading = true;
      this.form.validateFields((err, values) => {
        if (err) {
          return;
        }
        values['id'] = this.dataForm.id
        console.log('Received values of form: ', values);
        updateDeptTree(values).then(res => {
          if (res.code == 10000) {
            this.$message.success({
              content: '操作成功',
              duration: 1,
              onClose: () => {
                this.form.resetFields();
                this.visible = false;
                this.$emit('refreshDataList')
              }
            })
          } else {
            this.$message.error({
              content: res.msg,
              duration: 1
            })
          }

        })
      });
    },
    handleCancel (e) {
      this.visible = false;
    },
    init (id) {
      this.dataForm.id = id || 0
      this.visible = true
      this.$nextTick(() => {
        this.form.resetFields();
        if(this.dataForm.id){
          detailDeptTree(id).then(res=>{ 
            this.dataForm.id = res.data.id
            this.dataForm.remark = res.data.remark
            this.dataForm.deptShorterName = res.data.deptShorterName
            this.dataForm.deptName = res.data.deptName
            this.dataForm.sort = Number(res.data.sort)
          }).catch(error=>{
                console.log(error)
            })
        }
      });
    }
  }
}
</script>