<template>
  <a-modal
      title="新建用户"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @cancel="handleCancel"
      destroyOnClose
    >
      <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }" @keyup.enter.native="dataFormSubmit()">
        
        <a-form-item label="姓名">
          <a-input placeholder="请输入姓名" v-decorator="['name', { rules: [{ required: true, message: '请输入姓名' }] }]" />
        </a-form-item>

        <a-form-item label="密码">
          <a-input placeholder="请输入密码" v-decorator="['password', { rules: [{ required: true, message: '请输入密码' }] }]" type="password"/>
        </a-form-item>

        <a-form-item label="手机号">
          <a-input placeholder="请输入手机号" v-decorator="['phone', { rules: [{ required: true, message: '请输入手机号' }] }]" />
        </a-form-item>

        <a-form-item label="系统角色">
          <a-select style="width:100%" 
                  v-decorator="[
                    'roleId',
                    {rules: [{required: true,  message: '请选择系统角色'}]}
                  ]">
                  <a-select-option  v-for="item in roleList" :key="item.id">{{ item.name }}</a-select-option>
                </a-select>
        </a-form-item>
        
        <a-form-item label="邮箱">
          <a-input placeholder="请输入邮箱" v-decorator="['email']" />
        </a-form-item>
        <!-- <a-form-item label="头像" help="允许上传图片的格式为jpg、jpeg、gif、bmp、png, 建议尺寸大小：122 x 98，图像4MB以内">
           <a-upload
            v-decorator="['image']"
            list-type="picture-card"
            class="avatar-uploader"
            :show-upload-list="false"
            :before-upload="beforeUpload"
            :customRequest="customUploadRequest"
          >
            <img v-if="image" :src="image" alt="avatar" style="width:100px;height:100px" />
            <div v-else>
              <a-icon :type="loadingFile ? 'loading' : 'plus'" />
              <div class="ant-upload-text">
                上传
              </div>
            </div>
          </a-upload>
        </a-form-item> -->
        <a-form-item label="职务">
          <a-input placeholder="请输入职务" v-decorator="['jobTitle']" />
        </a-form-item>

        <a-form-item label="描述">
            <a-textarea placeholder="请输入描述" :rows="4" v-decorator="['remark']"/>
        </a-form-item>
      </a-form>
      <span slot="footer" class="dialog-footer">
        <a-button @click="visible = false">取消</a-button>
        <a-button class="ant-btn ant-btn-primary" @click="dataFormSubmit()">确定</a-button>
      </span>
    </a-modal>
</template>
<script>
import { addUser, getRoleList } from '@/httpsAPI/configure/index'
import { uploadImg } from '@/httpsAPI/common/index'
    export default {
        data(){
            return {
                form: this.$form.createForm(this),
                companyId: '',
                deptId:'',
                visible: false,
                confirmLoading: false,
                loadingFile: false,
                imageUrl: '',
                image:'',
                roleList: []
            }
        },
        methods:{
          getRoleData() {
            getRoleList({rangeType: 0}).then((res) => {
              this.roleList = res.data.data
            });
          },
          beforeUpload (file) {
            console.log('beforeUpload')
            console.log(file)
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
            if (!isJpgOrPng) {
              this.$message.error('仅支持图片上传');
            }
            const isLt2M = file.size / 1024 / 1024 < 4;
            if (!isLt2M) {
              this.$message.error('图片不能大于4MB!');
            }
            return isJpgOrPng && isLt2M;
          },
          customUploadRequest(option){
            const formData = new FormData() 
            formData.append('file', option.file)
            formData.append('channel', 2)
            this.saveFile(formData)
          },
          saveFile (formData) {
            console.log('saveFile')
            console.log(formData,"formData")
            this.loadingFile = true
            uploadImg(formData).then(res=>{
              console.log(res,"res")
                if (res.code == 10000) { 
                  this.image = this.$imgBaseUrl + res.data.name
                  this.loadingFile = false           
                  this.form.setFieldsValue({
                    image: res.data.name
                  })
                } else {  
                  this.$message.error(res.msg)  
                }
            })
          },
          dataFormSubmit(e) {
            this.confirmLoading = true;
            this.form.validateFields((err, values) => {
                if (err) {
                    console.log(err)
                    return;
                }
            
                values['deptId'] = this.deptId
                values['roleIdList'] = [values['roleId']]
                console.log('Received values of form: ', values);
                addUser(values).then(res=>{
                  if(res.code==10000) {
                    this.$message.success({
                        content: '操作成功',
                        duration: 1,
                        onClose: () => {
                            this.form.resetFields();
                            this.visible = false;
                            this.$emit('refreshDataList')
                        }
                    })
                  }else {
                    this.$message.error({
                        content: res.msg,
                        duration: 1
                    })
                  }
                })
            });
          },
          handleCancel(e) {
            this.visible = false;
          },
          init (deptId) {
            this.deptId = deptId
            this.visible = true

            this.$nextTick(() => {
                this.form.resetFields();

                this.getRoleData()
            });
          }
        }
    }
</script>