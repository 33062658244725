<template>
  <a-modal
      title="修改用户"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @cancel="handleCancel"
      destroyOnClose
    >
      <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }" @keyup.enter.native="dataFormSubmit()">
        <a-form-item label="姓名">
          <a-input placeholder="请输入姓名" v-decorator="['name', {initialValue:dataForm.name, rules: [{ required: true, message: '请输入姓名' }] }]" />
        </a-form-item>

        <a-form-item label="密码">
          <a-input placeholder="请输入密码" v-decorator="['password', {initialValue:dataForm.password, rules: [{ required: true, message: '请输入密码' }] }]" type="password" />
        </a-form-item>

        <a-form-item label="手机号">
          <a-input placeholder="请输入手机号" v-decorator="['phone', {initialValue:dataForm.phone, rules: [{ required: true, message: '请输入手机号' }] }]" @change="isPhone" />
        </a-form-item>

        <a-form-item label="系统角色">
          <a-select style="width:100%" 
                  v-decorator="[
                    'roleId',
                    {initialValue:dataForm.roleId, rules: [{required: true,  message: '请选择系统角色'}]}
                  ]" :disabled="dataForm.userType==1">
                  <a-select-option  v-for="item in roleList" :key="item.id">{{ item.name }}</a-select-option>
                </a-select>
        </a-form-item>
        <a-form-item label="部门">
          <a-tree-select
                  placeholder="请选择所属部门" 
                  v-decorator="['deptIds', {initialValue:dataForm.deptIds, rules: [{ required: true, message: '请选择所属部门' }] }]"
                  tree-data-simple-mode
                   multiple
                  allowClear
                  style="width: 100%"
                  :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                  :tree-data="deptSelectTreeData"
              />
        </a-form-item>
          <!-- <a-form-item label="部门">
           <a-select
                :filter-option="false" 
                mode="multiple"
                show-search 
                :allowClear="true"
                placeholder="请选择部门"
                v-decorator="['handlerId', { rules: [{ required: true, message: '部门' }] }]"
                >
                    <a-select-option v-for="item in masterList" v-bind:key="item.id" :value="item.id">
                        {{item.name }}
                    </a-select-option>
            </a-select>
        </a-form-item> -->
        
        <a-form-item label="邮箱">
          <a-input placeholder="请输入邮箱" v-decorator="['email',{initialValue:dataForm.email, rules: [{ message: '请输入邮箱' }] }]" />
        </a-form-item>
        
        <a-form-item label="职务">
          <a-input placeholder="请输入职务" v-decorator="['jobTitle',{initialValue:dataForm.jobTitle, rules: [{ message: '请输入组织职务' }] }]" />
        </a-form-item>

        <a-form-item label="描述">
            <a-textarea placeholder="请输入描述" :rows="4" v-decorator="['remark',{initialValue:dataForm.remark, rules: [{ message: '请输入描述' }] }]"/>
        </a-form-item>
      </a-form>
      <span slot="footer" class="dialog-footer">
        <a-button @click="visible = false">取消</a-button>
        <a-button class="ant-btn ant-btn-primary" @click="dataFormSubmit()">确定</a-button>
      </span>
    </a-modal>
</template>
<script>
import { detailUser, getRoleList, getDepartmentList, updateUser } from '@/httpsAPI/configure/index'
    export default {
        data(){
            return {
                form: this.$form.createForm(this),
                companyId: '',
                deptId:'',
                visible: false,
                confirmLoading: false,
                loadingFile: false,
                roleList: [],
                departmentList:[],
                deptSelectTreeData: [],
                dataForm: {
                  id: '',
                  roleId:0,
                  loginName: '',
                  name: '',
                  remark: '',
                  userType: 0
                }
            }
        },
        methods:{
          //判断手机号
          isPhone(){
            this.dataForm.password = ''
          },
          getRoleData() {
            getRoleList({rangeType: 0}).then((res) => {
              this.roleList = res.data.data
            });
          },

          dataFormSubmit(e) {
            this.confirmLoading = true;
            this.form.validateFields((err, values) => {
                if (err) {
                    console.log(err)
                    return;
                }
            
                values['id'] = this.deptId
                values['roleIdList'] = [values['roleId']]
                console.log('Received values of form: ', values);
                updateUser(values).then(res=>{
                  if(res.code==10000) {
                    this.$message.success({
                        content: '操作成功',
                        duration: 1,
                        onClose: () => {
                            this.form.resetFields();
                            this.visible = false;
                            this.$emit('refreshDataList')
                        }
                    })
                  }else {
                    this.$message.error({
                        content: res.msg,
                        duration: 1
                    })
                  }
                })
            });
          },
          handleCancel(e) {
            this.visible = false;
          },
          init (deptId) {
            this.visible = true

            this.$nextTick(() => {
              this.form.resetFields();
                this.deptId = deptId
                detailUser(this.deptId).then(res=>{ 
                  this.getRoleData()
                  getDepartmentList().then(deptRes=>{
                      this.deptSelectTreeData =  deptRes.data
                      console.log(this.deptSelectTreeData)
                      
                      this.dataForm.id = res.data.id
                      this.dataForm.loginName = res.data.loginName
                      this.dataForm.name = res.data.name
                      this.dataForm.deptIds = res.data.deptIds
                      this.dataForm.password = res.data.password
                      this.dataForm.phone = res.data.phone
                      this.dataForm.userType = res.data.userType
                      
                      if(res.data.roleIdList) {
                        this.dataForm.roleId = res.data.roleIdList[0] 
                      }
                      
                      this.dataForm.email = res.data.email
                      this.dataForm.jobTitle = res.data.jobTitle
                      this.dataForm.remark = res.data.remark
                  })
                }).catch(error=>{
                      console.log(error)
                  })
            });
          }
        }
    }
</script>