<template>
  <div class="configure-right" :style="{height: '100%'}">
    <h2 style="padding:20px;">项目设置</h2>
    <div>
      <a-row :gutter="12">
        <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
          <a-col :span="8" style="margin-left:20px">
            <a-form-item label="项目名称">
              <a-input v-model="queryParams.projectName" placeholder="项目名称"></a-input>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item>
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
              <a-button style="margin-left: 8px" @click="resetQueryForm">重置</a-button>
            </a-form-item>
          </a-col>
        </a-form>
      </a-row>
    </div>
    <!-- 详情弹窗 -->
    <detail-form v-if="detailFormVisible" ref="detailForm"></detail-form>
    <!-- 修改弹窗 -->
    <update-form v-if="updateFormVisible" ref="updateForm" @refreshDataList="$refs.table.refresh(true)"></update-form>

    <div class="antd-page-content">
      <s-table size="default" ref="table" rowKey="id" :data="loadData" :columns="columns" :pageNum="pageNum">
         <template slot="action" slot-scope="text,record">
          <!-- <a href="javascript:void(0)" class="table-operation-action" @click="openEvt(record)" v-if="record.activeStatus == 1">开启</a>
          <a href="javascript:void(0)" class="table-operation-action" @click="closeEvt(record)" v-if="record.activeStatus == 0">关闭</a> -->
          <a href="javascript:void(0)" class="table-operation-action" @click="updateFormEvt(record)">编辑</a>
          <a href="javascript:void(0)" class="table-operation-action" @click="detailFormEvt(record)">详情</a>
            <a-popconfirm
              title="确定删除此数据么？"
              ok-text="确定"
              cancel-text="取消"
              placement="topLeft"
              @confirm="delProjectEvt(record)"
            >
              <a-icon slot="icon" type="question-circle-o" style="color: red" />
              <a href="javascript:void(0)" class="table-operation-action">删除</a>
            </a-popconfirm>
          </template>
        <template slot="status" slot-scope="text,record">
          <a-tag color="red" v-if="record.status==0" >待开始</a-tag>
          <a-tag color="orange" v-if="record.status==1">进行中</a-tag>
          <a-tag color="green" v-if="record.status==2">已完成</a-tag>
        </template>
      </s-table>
    </div>
  </div>
</template>

<script>
import { getProjectList, delProjectList, openProject, closeProject } from '@/httpsAPI/configure/index'
import DetailForm from '../project/detail'
import UpdateForm from '../project/updateProjectSet'

let columns = [
  { title: "项目名称", dataIndex: "projectName" },
  { title: "负责人", dataIndex: "name", align: 'center' },
  { title: "联系方式", dataIndex: "phone", align: 'center' },
  { title: "状态", dataIndex: "activeStatus",scopedSlots: { customRender: 'status' }, align: 'center' },
  { title: "操作", dataIndex: "action", scopedSlots: { customRender: 'action' }, align: 'center' }
]
export default {
  name: "projectSet",
  data () {
    return {
      updateFormVisible: false,
      detailFormVisible: false,
      queryParams: {},
      columns,
      pageNum: 1,
      isOpen:null,
      loadData: parameter => {
        this.pageNum = parameter.pageNo
        return getProjectList(Object.assign(parameter,this.queryParams)).then(res => {
          return res.data
        })
      },
      currentMenu: [0],
      settingVisible: false,
      form: this.$form.createForm(this, { name: 'searchProject' }),
      selectedRowKeys: [],
      selectedRows: [],
    }
  },
   components:{
    DetailForm,
    UpdateForm
  },
  computed: {
  },
  methods: {
    // 重置搜索form
    resetQueryForm () {
      this.queryParams = {}
      this.$refs.table.refresh(true)
    },
    //删除用户
    delProjectEvt(item) {
      const that = this
      delProjectList([item.id]).then(res=>{
        if(res.code==10000) {
          that.$message.success({
              content: '操作成功',
              duration: 1,
              onClose: () => {
                  that.$refs.table.refresh(true)
              }
          })
          } else {
          that.$message.error({
              content: res.msg,
              duration: 1
          })
          }
      })
    },
    //开启
    openEvt (record) {
      console.log(record,"record")
      openProject(record.id).then(res => {
        console.log(res)
        // 刷新表格
        this.$refs.table.refresh()
        this.$message.success('开启成功')
      })
    },
    //关闭
    closeEvt (record) {
      closeProject(record.id).then(res => {
        // 刷新表格
        this.$refs.table.refresh()
        this.$message.success('关闭成功')
      })
    },
    // 详情
    detailFormEvt(record) {
      this.$router.push({path:"/projectManage/overview?projectId="+record.id})
    //   this.detailFormVisible = true
    //   this.$nextTick(() => {
    //     this.$refs.detailForm.init(record)
    //   })
    },
     // 修改
     updateFormEvt(record) {
      this.updateFormVisible = true
      this.$nextTick(() => {
        this.$refs.updateForm.init(record)
      })
    },
  }
}
</script>